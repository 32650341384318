import React, { Fragment, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ListBody from 'components/list/ListBody';
import ProgramsListItem from 'containers/partials/list-items/ProgramsListItem';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import List from 'components/list/List';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';

const ProgramsList = ({ entityId, programs, onClick, type }) => {
  const intl = useIntl();
  const defaultVisibleItems = 8;
  const [showAll, setShowAll] = useState(false);
  const { programId } = useParams();
  const parentRef = useRef();
  const [setMinHeight] = useState(null);

  useLayoutEffect(() => {
    if (parentRef.current) {
      const roundedTop = parentRef.current
        .getBoundingClientRect()
        .top.toFixed(0);

      setMinHeight(`calc(100vh - 16px - ${roundedTop}px)`);
    }
  }, [setMinHeight]);

  if (!Array.isArray(programs)) return null;

  if (programs.length > 0) {
    return (
      <List
        ref={parentRef}
        style={{
          overflowY: 'scroll',
          maxHeight: '100%',
          height: '100%',
          margin: '0 -16px',
          padding: 16
        }}
      >
        <ListBody>
          {programs.map((program, i) => (
            <Fragment key={program.id}>
              {(showAll || i < defaultVisibleItems) && (
                <ProgramsListItem
                  id={program.id}
                  program={program}
                  entityId={entityId}
                  onClick={() => onClick && onClick(program)}
                  type={type}
                  active={programId && program.id === programId}
                />
              )}
            </Fragment>
          ))}
        </ListBody>
        <ButtonsGroup extraClassNames="o-flex--justify-center">
          {programs.length > defaultVisibleItems && (
            <Button rounded grey onClick={() => setShowAll(!showAll)}>
              <Icon id={!showAll ? 'dropdown-open' : 'dropdown-closed'} />
              {showAll
                ? intl.formatMessage(messages.exercisesProgramsButtonShowLess)
                : intl.formatMessage(messages.exercisesProgramsButtonShowAll)}
            </Button>
          )}
        </ButtonsGroup>
      </List>
    );
  }

  return (
    <Card centered>
      <CardBody empty>
        <Message iconFillColor="color-neutral-dark" empty icon="browse">
          <MessageText>
            {type === 'user' ? (
              <FormattedMessage {...messages.athleteProgramsEmpty} />
            ) : (
              <FormattedMessage {...messages.programsEmpty} />
            )}
          </MessageText>
        </Message>
      </CardBody>
    </Card>
  );
};

export default ProgramsList;
