import classNames from 'classnames';
import { Field, ErrorMessage } from 'formik';
import InputErrorMessage from './InputErrorMessage';

const FieldCheckbox = props => {
  return (
    <div
      className={classNames('c-input__group c-input__group--checkbox', {
        'c-input--checkbox--in-list': props.inListStatus,
        'c-input--checkbox--checked': props.defaultChecked,
        'c-input--checkbox--visible': props.visible,
        'c-input__checkbox--full': props.extraLabel,
        'c-input--checkbox--secondary': props.secondary,
        'c-input--checkbox--simple': props.simple,
        'c-input--checkbox--indeterminate': props.indeterminate,
        'c-input--checkbox--rounded': props.rounded
      })}
    >
      <Field
        id={props.id}
        name={props.name}
        type="checkbox"
        className={classNames(
          'c-input c-input--checkbox',
          props.inputExtraClassNames,
          {
            'c-input--checkbox-disabled': props.readOnly
          }
        )}
        disabled={props.readOnly}
      />

      <label
        htmlFor={props.id}
        className={classNames('c-input__label c-input__label--checkbox')}
      >
        {props.children && !props.simple ? (
          <div className="c-input__text-group">
            <span className="c-input__text">{props.children}</span>
            {props.extraLabel && (
              <span className="c-input__extra-label">{props.extraLabel}</span>
            )}
          </div>
        ) : props.children && props.simple ? (
          props.children
        ) : (
          ''
        )}
      </label>
      {props.errors && props.touched?.[props.name] && (
        <ErrorMessage name={props.name} component={InputErrorMessage} />
      )}
    </div>
  );
};

export default FieldCheckbox;
