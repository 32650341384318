import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Drawer from 'components/drawer/Drawer';
import {
  Navigation,
  NavigationItem,
  NavigationWrapper,
  NavigationItemsGroup
} from 'components/navigation';

import messages from 'messages';
import { DrawerHeader } from 'components/drawer';
import {
  ROUTE_SETTINGS,
  ROUTE_RESULTS,
  ROUTE_RESULTS_ENTITY_POPUP,
  ROUTE_SPORTERS,
  ROUTE_GROWTH_PREDICTION,
  ROUTE_GROWTH_PREDICTION_ENTITY_POPUP,
  ROUTE_HAN_SUPPORT,
  ROUTE_SUPPORT
} from 'routes/RouteList';
import { drawerGroups, PackageTypes } from 'constants.js';
import { StoreContext } from 'index';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { useHistory } from 'react-router-dom';
import { MODAL_TYPES } from 'models/ModalData';

const DrawerView = props => {
  const {
    authStore,
    authStore: { user },
    uiState,
    routing
  } = useContext(StoreContext);
  const { push } = useHistory();
  const intl = useIntl();
  const ability = useAbility(AbilityContext);
  const [drawerType, setDrawerType] = useState([]);

  const logout = () => {
    closeDrawer();
    authStore.doLogout();
  };

  const navigationClickedHandler = (event, item, active) => {
    event.preventDefault();

    closeDrawer();

    const currentRoute = routing.location.pathname;
    let route = item.route;
    // benchmark popup fix
    if (route === ROUTE_RESULTS) {
      if (
        !active ||
        (currentRoute === ROUTE_RESULTS && user && user.rootEntityId)
      ) {
        route = ROUTE_RESULTS_ENTITY_POPUP.replace(
          ':entityId',
          user.rootEntityId
        );
        routing.push(route);
      } else if (routing.location.pathname.search('popup') === -1) {
        route = routing.location.pathname.replace('default', 'popup');
        routing.push(route);
      }
    } else {
      routing.push(route);
    }

    // benchmark popup fix
    if (route === ROUTE_GROWTH_PREDICTION) {
      if (
        !active ||
        (currentRoute === ROUTE_GROWTH_PREDICTION && user && user.rootEntityId)
      ) {
        route = ROUTE_GROWTH_PREDICTION_ENTITY_POPUP.replace(
          ':entityId',
          user.rootEntityId
        );
        routing.push(route);
      } else if (routing.location.pathname.search('popup') === -1) {
        route = routing.location.pathname.replace('default', 'popup');
        routing.push(route);
      }
    } else {
      routing.push(route);
    }
  };

  const isOpenDrawer = props.isOpenDrawer;
  const closeDrawer = props.handleClickOutside;

  useEffect(() => {
    if (props.type) {
      setDrawerType(drawerGroups(props.type));
    }
  }, [user, props.type]);

  const hasBenchmarks = user?.testSets?.find(
    ts => ts.type === PackageTypes.IDO
  );

  const navigateToHome = () => {
    push(uiState.homeRoute);
  };

  const openSupportModal = () => {
    uiState.showModal({
      title: intl.formatMessage(messages.modalSupportTitle),
      message: intl.formatMessage(
        user.isHanOrg
          ? messages.modalSupportHanMessage
          : messages.modalSupportMessage,
        {
          email: user.isHanOrg
            ? '<a href="mailto:seneca@han.nl">seneca@han.nl</a>'
            : '<a href="mailto:support@hylyght.com">support@hylyght.com</a>',
          br: '<br />'
        }
      ),
      type: MODAL_TYPES.WARNING,
      okLabel: intl.formatMessage(
        user.isHanOrg
          ? messages.modalSupportHanOkButton
          : messages.modalSupportOkButton
      ),
      dismissLabel: intl.formatMessage(messages.modalSupportDismissButton),
      okHandler: async () => {
        window.open(
          user.isHanOrg ? ROUTE_HAN_SUPPORT : ROUTE_SUPPORT,
          '_blank'
        );
      }
    });
  };

  return (
    <Drawer open={isOpenDrawer} handleClickOutside={() => closeDrawer()}>
      <DrawerHeader
        title={user?.name}
        logo={user?.logo}
        onClick={() => {
          closeDrawer();
          navigateToHome();
        }}
      />
      <Navigation>
        <NavigationWrapper>
          {user &&
            drawerType.map((groupitem, i) => {
              const items = groupitem.items.filter(item =>
                ability.can('read', item.ability)
              );
              if (items.length) {
                return (
                  <NavigationItemsGroup key={i}>
                    {items.map(item => {
                      const currentRoute = routing.location.pathname;
                      /*if (item.ability) {
                        if (!ability.can('manage', item.ability)) {
                          return null;
                        }
                      }*/
                      const active =
                        `${currentRoute.split('/')[1]}` ===
                          `${item.route.split('/')[1]}` ||
                        (item.subroutes &&
                          item.subroutes.some(
                            route =>
                              `${route.split('/')[1]}` ===
                              `${currentRoute.split('/')[1]}`
                          ));

                      if (user.hasNoRoles() && user.hasGroupsToShow) {
                        if (item.route !== ROUTE_SPORTERS) {
                          return null;
                        }
                      }

                      if (
                        item.route !== ROUTE_RESULTS ||
                        hasBenchmarks ||
                        hasBenchmarks
                      ) {
                        return (
                          <NavigationItem
                            key={item.route}
                            active={active}
                            icon={item.icon}
                            iconSize={item.iconSize}
                            onClick={e => {
                              closeDrawer();
                              navigationClickedHandler(e, item, active);
                            }}
                          >
                            <FormattedMessage {...item.label} />
                          </NavigationItem>
                        );
                      }
                      return null;
                    })}
                  </NavigationItemsGroup>
                );
              }
              return null;
            })}
          {user &&
            user.hasAdminRole() &&
            !user.isGSVOrg &&
            process.env.REACT_APP_INTERCOM_ENABLED === 'true' && (
              <NavigationItem
                id={'intercom-launcher'}
                icon="question"
                bottom
                style={{ bottom: '60px' }}
              >
                <FormattedMessage {...messages.drawerSupport} />
              </NavigationItem>
            )}
          {user &&
            user.hasAdminRole() &&
            !user.isGSVOrg &&
            (user.isHylyghtOrg || user.isHanOrg) &&
            process.env.REACT_APP_INTERCOM_ENABLED === 'false' && (
              <NavigationItem
                id={'faq-launcher'}
                icon="question"
                bottom
                onClick={openSupportModal}
                style={{ bottom: '60px' }}
              >
                <FormattedMessage {...messages.drawerSupport} />
              </NavigationItem>
            )}

          {user && ability.can('manage', 'Settings') && (
            <NavigationItem
              icon="settings"
              bottom
              active={
                `${routing.location.pathname.split('/')[1]}` ===
                `${ROUTE_SETTINGS.split('/')[1]}`
              }
              onClick={() => {
                closeDrawer();
                push(ROUTE_SETTINGS);
              }}
            >
              <FormattedMessage {...messages.drawerSettings} />
            </NavigationItem>
          )}
        </NavigationWrapper>

        <NavigationItem secondary signout icon="signout" onClick={logout}>
          <FormattedMessage {...messages.drawerLogout} />
        </NavigationItem>
      </Navigation>
    </Drawer>
  );
};
export default DrawerView;
