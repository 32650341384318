import { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { generatePath } from 'react-router-dom';
import * as Yup from 'yup';
import { ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY } from 'routes/RouteList';
import { usePersonContext } from 'contexts/PersonContext';
import { useMedicalContext } from 'contexts/MedicalContext';
import { StoreContext } from 'index';
import {
  MUTATION_ADD_SCREENING,
  MUTATION_EDIT_SCREENING
} from 'services/aws/screenings-query';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { MUTATION_ADD_TESTSET } from 'services/aws/testsets-query';
import { QUERY_GET_PERSON_MEDICALS } from 'services/aws/injury-query';
import { TestSetV2Type } from 'constants.js';
import { useNotificationQueue } from 'components/notification';
import messages from 'messages';
import classNames from 'classnames';
import Session from 'models/Session';
import { parseDateString } from 'utils/date';
import { format } from 'date-fns';

function ScreeningForm({
  submitRef,
  entityId,
  personInjury,
  extraClassNames,
  onSubmitted,
  children,
  ...props
}) {
  const {
    routing: { push },
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    startdate: Yup.date()
      .transform(parseDateString)
      .typeError(intl.formatMessage(messages.dateRequired))
      .required(intl.formatMessage(messages.screeningDateRequired)),
    name: Yup.string().required(
      intl.formatMessage(messages.screeningNameRequired)
    )
  });

  const notification = useNotificationQueue();

  const { person } = usePersonContext();
  const { medical } = useMedicalContext();

  const [screening] = useState(
    new Session({ ...props.screening, type: TestSetV2Type.REHAB })
  );

  const [addScreening] = useMutation(MUTATION_ADD_SCREENING);
  const [editScreening] = useMutation(MUTATION_EDIT_SCREENING);
  const [addTestSet] = useMutation(MUTATION_ADD_TESTSET);

  /*useEffect(() => {
    setScreening({
      ...initialScreening,
      milestoneTestSetId:
        initialScreening?.testSetTemplateIds?.[0] ??
        (initialScreening.id ? '0' : '') // only render the 0 when we already have a screening without a milestone
    });
  }, [initialScreening]);*/

  const handleSubmit = async (screening, { resetForm }) => {
    if (screening.id) {
      //edit
      const testSession = {
        id: screening.id,
        entityId: rootEntityId,
        name: screening.name,
        testSetIds: screening.testSetIds,
        testSetTemplateIds:
          screening.milestoneTestSetId === '0'
            ? []
            : [screening.milestoneTestSetId],
        startdate: format(screening.startdate, 'yyyy-LL-dd'),
        type: TestSetV2Type.REHAB
      };

      await editScreening({
        variables: { personInjuryId: personInjury.id, testSession },
        refetchQueries: [
          {
            query: QUERY_GET_PERSON_MEDICALS,
            variables: { entityId: rootEntityId, personId: person.id }
          }
        ]
      }).then(res => {
        if (onSubmitted) onSubmitted();
        notification.add(res.data.editScreening.id, {
          message: intl.formatMessage(messages.rehabScreeningSaved)
        });
      });
    } else {
      // add

      const testSetData = await addTestSet({
        variables: {
          entityId: rootEntityId,
          title: screening.name,
          testIds:
            screening.tests?.length > 0
              ? screening.tests.map(test => test.id)
              : screening.milestoneTestSet?.tests?.length
              ? screening.milestoneTestSet.tests.map(test => test.id)
              : [],

          type: TestSetV2Type.REHAB,
          injuryId: personInjury.injury.id
        }
      });

      const testSetId = testSetData.data.addTestSetV2.id;

      const testSession = {
        entityId: rootEntityId,
        name: screening.name,
        testSetIds: [testSetId],
        testSetTemplateIds:
          screening.milestoneTestSetId === '0'
            ? []
            : [screening?.milestoneTestSet?.id ?? screening.milestoneTestSetId],
        startdate: format(screening.startdate, 'yyyy-LL-dd'),
        type: TestSetV2Type.REHAB
      };

      await addScreening({
        variables: { personInjuryId: personInjury.id, testSession },
        refetchQueries: [
          {
            query: QUERY_GET_PERSON_MEDICALS,
            variables: { entityId: rootEntityId, personId: person.id }
          }
        ]
      }).then(res => {
        resetForm();
        if (onSubmitted) onSubmitted();
        notification.add(res.data.addScreening.id, {
          message: intl.formatMessage(messages.rehabScreeningSaved)
        });
        push(
          generatePath(
            ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
            {
              entityId,
              sporterId: person.id,
              medicalId: medical.id,
              injuryId: personInjury.id,
              sessionId: res.data.addScreening.id
            }
          )
        );
      });
    }
  };

  return (
    <Formik
      initialValues={screening}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={submitRef}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ScreeningForm;
