import { sort, SORT_DATA_TYPES } from './sort';
import { groupBy } from 'utils/array';
import { sortByKey } from 'utils/object';
import { LeftRight } from 'constants.js';

// Deprecated method, use groupTests, only used in TrainingSelectTests
export const getTestGroups = tests => {
  const sortedTests = sort(tests, { keys: [{ key: 'title' }] });
  const rawGroups = sortedTests.reduce((accum, test) => {
    const group = accum.find(a => a.id === test.category);

    if (group) {
      group.children.push(test);
      return accum;
    }
    return [
      ...accum,
      {
        id: test.category,
        label: test.category,
        children: [test]
      }
    ];
  }, []);

  return sort(rawGroups, {
    keys: [{ key: 'label' }]
  });
};

export const groupTests = testsList => {
  const groupedTests = groupBy(testsList, ['category']);
  for (const [groupId, tests] of Object.entries(groupedTests)) {
    groupedTests[groupId] = sort(tests, { keys: [{ key: 'title' }] });
  }
  return sortByKey(groupedTests);
};

export const testItemHasValues = (test, sides) => {
  return (
    sides &&
    Object.keys(sides).some(key => {
      return test?.[key] && test[key].some(value => value !== '');
    })
  );
};

export const checkLateralityIsDisabled = (test, sides) => {
  const lateralitySides = Array.isArray(sides)
    ? sides
    : Object.keys(sides)
        .map(side => (sides[side] ? side : null))
        .filter(s => s !== 'center' && s !== null);

  switch (test.laterality) {
    case 'bilateral_independent':
      if (!sides) return [false, false];
      return [
        lateralitySides.length > 0 && !lateralitySides.includes(LeftRight.LEFT),
        lateralitySides.length > 0 && !lateralitySides.includes(LeftRight.RIGHT)
      ];
    case 'bilateral_dependent':
      return [false, false];
    case 'unilateral':
    default:
      return [false];
  }
};

export const checkLateralityIsHidden = (testResult, sides, person) => {
  switch (testResult.laterality) {
    case 'bilateral_independent':
      return [
        (sides?.length > 0 && !sides.includes(LeftRight.LEFT)) ||
          (testResult.dominant_only && person.dominantHand !== LeftRight.LEFT),
        (sides?.length > 0 && !sides.includes(LeftRight.RIGHT)) ||
          (testResult.dominant_only && person.dominantHand !== LeftRight.RIGHT)
      ];
    case 'bilateral_dependent':
      return [
        (sides?.length > 0 && !sides.includes(LeftRight.LEFT)) ||
          (testResult.dominant_only && person.dominantHand !== LeftRight.LEFT),
        (sides?.length > 0 && !sides.includes(LeftRight.RIGHT)) ||
          (testResult.dominant_only && person.dominantHand !== LeftRight.RIGHT)
      ];
    case 'unilateral':
    default:
      return [false];
  }
};

export const getTestSetGroups = testSets => {
  const sortedTestSets = sort(testSets, {
    keys: [
      { key: 'title' },
      { key: 'version', desc: true, dataType: SORT_DATA_TYPES.NUMBER }
    ]
  });
  return getRawGroups(sortedTestSets);
};

export const getRawGroups = tests => {
  const rawGroups = tests.reduce((accum, testSet) => {
    const groupLabel = testSet.id === testSet.originalId ? testSet.title : '';
    const group = accum.find(a => a.id === testSet.originalId);
    if (group) {
      group.label = group.label || groupLabel;
      group.children.push(testSet);
      return accum;
    }
    return [
      ...accum,
      {
        id: testSet.originalId,
        label: groupLabel || '',
        children: [testSet]
      }
    ];
  }, []);

  const sortedGroups = sort(rawGroups, {
    keys: [{ key: 'label' }]
  });

  return sortedGroups.map(sg => {
    sg.children = sort(sg.children, {
      keys: [
        {
          key: 'version',
          desc: true,
          dataType: SORT_DATA_TYPES.NUMBER
        }
      ]
    });
    return sg;
  });
};

export const getUniqueTestItems = tests => {
  const testItems = tests.reduce((tAccum, test) => {
    if (!test.testItems) {
      return tAccum.concat([]);
    }
    return tAccum.concat(
      test.testItems.reduce((tiAccum, ti) => {
        tAccum.findIndex(tia => tia.id === ti.id) === -1 && tiAccum.push(ti);
        return tiAccum;
      }, [])
    );
  }, []);
  return sort(
    testItems.filter(ti => !ti.hidden),
    { keys: [{ key: 'title' }] }
  );
};
