import { format } from 'date-fns';

export default class File {
  id = '';
  type = '';
  key = '';
  linkId = '';
  filename = '';
  fileType = '';
  status = '';
  meta = {};
  signedDownloadUrl = '';
  date_created;
  sharedWithAthlete = false;
  constructor({
    id,
    type,
    key,
    linkId,
    filename,
    status,
    meta,
    signedDownloadUrl,
    date_created,
    sharedWithAthlete
  }) {
    this.id = id;
    this.type = type;
    this.key = key;
    this.linkId = linkId;
    this.filename = filename;
    this.status = status;
    try {
      this.meta = meta ? JSON.parse(meta) : null;
    } catch (e) {
      this.meta = meta;
    }
    this.signedDownloadUrl = signedDownloadUrl;

    try {
      this.date_created = date_created
        ? format(new Date(date_created), 'dd/LL/yyyy')
        : null;
    } catch (e) {
      this.date_created = date_created;
    }
    this.sharedWithAthlete = sharedWithAthlete ?? false;

    this.fileType = type
      ? type.endsWith('image')
        ? 'image'
        : type.endsWith('video')
        ? 'video'
        : type.endsWith('video_legacy')
        ? 'video_legacy'
        : ''
      : '';
  }
}
