import React, { useEffect, useState, useContext } from 'react';
import Select, { Option } from 'components/input/Select';
import { QUERY_GET_INJURIES } from 'services/aws/injury-query';
import { useQuery } from '@apollo/client';
import { TestSetV2Type } from 'constants.js';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { uid } from 'react-uid';
import { StoreContext } from 'index';
import { ErrorMessage } from 'formik';
import InputErrorMessage from 'components/input/InputErrorMessage';
import FieldCheckbox from 'components/input/FieldCheckbox';

function InjurySelect({
  entityId,
  onChange,
  value,
  personInjury,
  readOnly = false,
  errors,
  otherOption = false
}) {
  const {
    authStore: {
      user: { baseLanguage }
    }
  } = useContext(StoreContext);
  const intl = useIntl();
  const [regions, setRegions] = useState([]);
  const [injuries, setInjuries] = useState([]);
  const [injuryOptions, setInjuryOptions] = useState([]);
  const [region, setRegion] = useState(null);
  const [sides, setSides] = useState([]);
  const { loading, error, data } = useQuery(QUERY_GET_INJURIES, {
    fetchPolicy: 'cache-first',
    variables: { entityId, types: [TestSetV2Type.REHAB] },
    skip: !entityId
  });
  useEffect(() => {
    if (data?.getInjuries) {
      const regionList = data.getInjuries.reduce((regions, injury) => {
        let region = regions.find(r => r.title === injury.region.title);
        if (!region) {
          region = {
            ...injury.region,
            copy: JSON.parse(injury.region.copy)
          };
          region.injuries = [{ ...injury }];
          regions.push(region);
        } else {
          region.injuries.push({ ...injury });
        }
        return regions;
      }, []);

      setRegions(
        sort(regionList, {
          keys: [{ key: 'order', dataType: SORT_DATA_TYPES.NUMBER }]
        })
      );
      setInjuries(sort(data.getInjuries, { keys: [{ key: 'title' }] }));
      if (value) {
        const selectedItem = data.getInjuries.find(i => i.id === value);
        setSides(selectedItem && selectedItem.sides);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, value]);

  useEffect(() => {
    if (value) {
      setRegion(injuries.find(i => i.id === value)?.region.title);
    }
  }, [value, injuries]);

  useEffect(() => {
    if (region && regions) {
      setInjuryOptions(regions.find(r => r.title === region)?.injuries);
    }
  }, [region, regions, injuries]);

  if (error)
    return (
      <Select
        label={intl.formatMessage(messages.labelInjury)}
        value=""
        disabled
      >
        <Option
          value=""
          label={intl.formatMessage(messages.errorMessageInjuriesSelect)}
        />
      </Select>
    );

  if (loading) {
    return (
      <Select label={intl.formatMessage(messages.labelInjury)} value="">
        <Option value="" label={intl.formatMessage(messages.globalLoading)} />
      </Select>
    );
  }

  return (
    <>
      <div className="o-layout__item">
        <Select
          id={`region_${uid(personInjury)}`}
          label={intl.formatMessage(messages.labelRegion)}
          name="region"
          value={region}
          onChange={selectedValue => {
            setRegion(selectedValue.value);
            setSides(selectedValue && selectedValue.sides);
            /*onChange &&
            onChange(regions.find(i => selectedValue.value === i.title));*/
          }}
          readOnly={readOnly}
          disabled={loading || readOnly}
        >
          {regions.map(region => (
            <Option
              key={uid(region.title)}
              value={region.title}
              label={region.copy?.[baseLanguage] || region.title}
            />
          ))}
        </Select>
      </div>
      <div className="o-layout__item u-1-of-2">
        <Select
          id={`injury_${uid(personInjury)}`}
          label={intl.formatMessage(messages.labelInjury)}
          name="injury"
          onChange={selectedValue => {
            onChange &&
              onChange(injuries.find(i => selectedValue.value === i.id));
          }}
          value={value}
          readOnly={readOnly}
          disabled={loading || readOnly}
          placeholder={
            injuryOptions.length === 0
              ? intl.formatMessage(messages.rehabInjuryNoRegionWarning)
              : ''
          }
          errors={errors}
        >
          {injuryOptions.map(injury => (
            <Option key={injury.id} value={injury.id} label={injury.title} />
          ))}
          {otherOption && (
            <Option
              value="other"
              label={intl.formatMessage(messages.otherResultsOption)}
            />
          )}
        </Select>
      </div>
      <div className="o-layout__item u-1-of-2">
        {sides && sides.length > 1 && (
          <div className="c-input--fieldset-checkbox c-input--fieldset-checkbox-two-inline">
            <p className="c-input__label">
              <FormattedMessage {...messages.rehabInjurySideLabel} />
            </p>
            {sides.map(side => (
              <FieldCheckbox
                key={side}
                id={`${side}_${uid(personInjury)}`}
                name={`sides[${side}]`}
                value={side}
                readOnly={readOnly}
                disabled={loading || readOnly}
                errors={errors}
                extraClassNames={'u-margin-bottom-small'}
              >
                <FormattedMessage
                  {...messages.rehabInjurySideValue}
                  values={{
                    side: side
                  }}
                />
              </FieldCheckbox>
            ))}
            {errors?.['sides'] && (
              <ErrorMessage name={'sides'} component={InputErrorMessage} />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default InjurySelect;
